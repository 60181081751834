import React, { useRef, useEffect } from 'react'
import Modal from 'react-modal'
import {
  ModalTitle,
  ModalHR,
  ModalControls,
  ModalContent,
} from '../../common/ModalParts'
import { renderText } from '../helpers/render-text'
import { renderButtons } from '../helpers/render-buttons'
import { getStyle } from '../helpers/get-style'
import { LogsAPI } from '../../../Modules/logs-api'
import { getModalOpenLogText } from '../helpers/get-modal-open-log-text'
import { useCloseOnClickOutside } from '../helpers/use-close-on-click-outside'

export const modalActions = {
  confirm: 'confirm',
  close: 'close',
}

const defaultButtons = [
  {
    text: 'Отмена',
    color: 'danger',
    action: modalActions.close,
    position: 'left',
  },
  {
    text: 'Подтвердить',
    color: 'success',
    action: modalActions.confirm,
    position: 'right',
  },
]

export function confirm({
  title = 'Подтвердите действие',
  text = 'Вы уверены что хотите это сделать?',
  buttons = defaultButtons,
  width = 640,
  disableCloseOnClickOutside = false,
}) {
  return ({ isOpen, resolve }) => {
    const ref = useRef()

    const setRef = value => {
      ref.current = value
    }

    useEffect(() => {
      const logText = getModalOpenLogText({ title, text })
      LogsAPI.event(logText)
    }, [])

    useCloseOnClickOutside({
      ref,
      disableCloseOnClickOutside,
      onClose: () => resolve(modalActions.close),
    })

    return (
      <Modal
        isOpen={isOpen}
        contentRef={setRef}
        style={getStyle({ width, zIndex: 1000 })}
      >
        <ModalTitle>{title}</ModalTitle>
        <ModalHR />
        <ModalContent>{renderText(text)}</ModalContent>
        <ModalHR />
        <ModalControls>
          {renderButtons({
            buttons,
            defaultButtons,
            resolve,
            beforeClick: ({ text }) => LogsAPI.pressAction(text),
          })}
        </ModalControls>
      </Modal>
    )
  }
}

confirm.actions = modalActions
confirm.defaultButtons = defaultButtons
