import React from 'react'
import styled from 'styled-components'
import {callModal, modalTemplates} from '../../../../dynamic-modal'
import Emoji from 'react-emoji-render'
import {GroupIconGrid} from '../../../../common/Icons'
import {icons} from './BodyCell'
import {room_statuses} from "../room-statuses/room-statuses";
import {useSelector} from "react-redux";
import {settingsSelectors} from "../../../../../Modules/settings";
import {LoyaltyStatus} from "../../../../LoyaltyStatus";

const subStyle = {
    alignItems: "baseline",
    transform: "scale(.8)",
    justifyContent: "flex-end",
    width: "100%"
}
const Flex = styled.div`
    display: flex;
    justify-content: space-between;
`

const InlineItem = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
`

const InlineFlexItem = styled(InlineItem)`
    display: inline-flex;
    padding-right: 15px`

const Cell = styled.div`
    margin: 5px 5px 5px 0;
    width: 30px !important;
    height: 30px !important;
    border-right: inherit !important;

    span {
        display: flex;
        width: 1em;
        margin-left: 15%;
        align-items: center;
        height: 100%;
    }

    svg {
        height: 100% !important;
        display: block;
        margin: auto !important;
    }
`
const RoomCell = styled(Cell)`
    width: 160px !important;
`

const ShowLegend = styled.div`
    font-size: 12px;
    margin: 5px 0;
    font-weight: 600;
    color: white;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-offset: 3px;
    width: fit-content;
    cursor: pointer;

    svg {
        vertical-align: middle;
        margin-left: 5px;
    }
`
const infoColor = [
    {
        className: "has-refund",
        text: "Невозвратная бронь"
    },
    {
        className: "not-payed",
        text: "Бронь без оплаты"
    },
    {
        className: "payed",
        text: "Оплаченная бронь"
    },
    {
        className: "canceled",
        text: "Отмененная бронь"
    },
    {
        className: "archived",
        text: "Архивная бронь"
    }
]

const infoIcon = [{
    icon: icons["Безналичный расчет"],
    text: "Безналичный расчет",
},
    {
        icon: icons["Карта"],
        text: "Карта"
    },
    {
        icon: icons["Наличные"],
        text: "Наличные"
    },
    {
        icon: icons["Поздний выезд"],
        text: "Поздний выезд",
        style: subStyle
    },
    {
        icon: icons["Примечание клиента"],
        text: "Примечание клиента",
        style: subStyle
    }
]

const loyalStatuses = [
    {title: "Золото", number: 3},
    {title: "Серебро", number: 2},
    {title: "Бронза", number: 1}
]

export const InfoModal = () => {
    const isRoomStatusesEnabled = useSelector(settingsSelectors.isRoomStatusesEnabled)
    return (<>
            <Flex>
                <div>
                    {infoColor.map(({className, text}) => (
                        <InlineItem>
                            <Cell className={`tbody-cell ${className}`}/>
                            - {text}
                        </InlineItem>
                    ))}
                    {isRoomStatusesEnabled && <InlineItem>
                        <Cell className="tbody-cell repair"/>
                        - Ремонт
                    </InlineItem>}
                </div>
                <div>
                    {infoIcon.map(({icon, text = "", style = {}}) => (
                        <InlineItem>
                            <Cell className="tbody-cell">
                                <Emoji
                                    text={icon}
                                    style={style}
                                    className="grid-additional-icon"
                                />
                            </Cell>
                            - {text}
                        </InlineItem>
                    ))}
                    <InlineItem>
                        <Cell className="tbody-cell">
                            <div className="migrated lt"/>
                        </Cell>
                        - Переселение брони по категориям/номерам
                    </InlineItem>
                    <InlineItem className="reservation-is-group">
                        <Cell style={{border: "1px solid #b1b1b1"}}><GroupIconGrid/></Cell>
                        - Групповая бронь
                    </InlineItem>
                </div>
            </Flex>
            {isRoomStatusesEnabled && <><b>Статус кoмнаты:</b>
                <div>
                    {Object.values(room_statuses).map(({name, styles}) =>
                        <InlineFlexItem>
                            <RoomCell style={styles}/>
                            - {name}
                        </InlineFlexItem>
                    )}
                </div>
            </>}
            <><b>Уровни в программе лояльности:</b>
                <div>
                    {loyalStatuses.map(({title, number}) =>
                        <div>
                            <LoyaltyStatus status={number}/>- {title}
                        </div>
                    )}
                </div>
            </>
        </>
    )
}


const QuestionIcon = () => (
    <svg
        fill="none"
        height="14"
        viewBox="0 0 14 14"
        width="14"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="7" cy="7" fill="#428DFF" r="7"/>
        <path
            d="M7 11.0332C7.37754 11.0332 7.68359 10.7271 7.68359 10.3496C7.68359 9.97207 7.37754 9.66602 7 9.66602C6.62246 9.66602 6.31641 9.97207 6.31641 10.3496C6.31641 10.7271 6.62246 11.0332 7 11.0332Z"
            fill="white"
        />
        <path
            d="M7 3.51367C5.79381 3.51367 4.8125 4.49498 4.8125 5.70117C4.8125 6.00321 5.05734 6.24805 5.35938 6.24805C5.66141 6.24805 5.90625 6.00321 5.90625 5.70117C5.90625 5.09808 6.39691 4.60742 7 4.60742C7.60309 4.60742 8.09375 5.09808 8.09375 5.70117C8.09375 6.30427 7.60309 6.79492 7 6.79492C6.69796 6.79492 6.45312 7.03976 6.45312 7.3418V8.70898C6.45312 9.01102 6.69796 9.25586 7 9.25586C7.30204 9.25586 7.54688 9.01102 7.54688 8.70898V7.81946C8.4893 7.57602 9.1875 6.71861 9.1875 5.70117C9.1875 4.49498 8.20619 3.51367 7 3.51367Z"
            fill="white"
        />
    </svg>
)


export const ShowTableLegend = () => {
    const showModal = () => (
        callModal(
            modalTemplates.alert({
                title: 'Условные обозначения в шахматке',
                text: <InfoModal/>,
            })
        )
    )

    return (
        <ShowLegend onClick={showModal}>
            Условные обозначения
            <QuestionIcon/>
        </ShowLegend>
    )
}
