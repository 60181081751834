import React from "react";
import {FaStar} from "react-icons/fa";

const commonStyle = {
    marginRight: '5px',
    marginBottom: '-3px',
    fontSize: '18px',
}

const statuses = {
    3: {color: "#C9B037"},
    2: {color: "#B4B4B4"},
    1: {color: "#6A3805"}
}

export const LoyaltyStatus = ({status}) =>
    <FaStar style={{...commonStyle, ...statuses[status]}}/>

