import {callModal, modalTemplates} from "../dynamic-modal";
import history from "../../Modules/helpers/history";
import {logoutAdmin} from "../../Modules/session/actions";
import {checkShift, openShift, resetShift} from "../../Modules/shifts/actions";
import {OPENED} from "../../Modules/shifts/selectors";

export const getOpenShiftModal = (lcode, isShiftChanging) => async (dispatch, getState) => {
    await dispatch(checkShift(lcode))
    const {shifts, settings} = getState()

    if (!settings.isChecklistEnabled) return

    const {checklistsCompleted, status, lastWorkShiftStatus} = shifts

    if (status !== OPENED) {
        if (!isShiftChanging && lastWorkShiftStatus && lastWorkShiftStatus === OPENED) {
            return dispatch(changeShiftModal(lcode))
        }
        return dispatch(openShiftModal(lcode))
    } else if (!checklistsCompleted) {
        return openCheckListModal(lcode)
    }

    return true
}

export const getCloseShiftModal = (lcode) => async (dispatch, getState) => {
    const {settings} = getState()

    if (!settings.isChecklistEnabled) {
        dispatch(logoutAdmin())
        return
    }

    dispatch(getOpenShiftModal(lcode)).then(res => {
        if (res)
            dispatch(closeShiftModal(lcode))
    })
}

const closeShiftModal = () => async (dispatch) => {
    const action = await callModal(
        modalTemplates.confirm({
            text:
                'Вы желаете сдать смену?',
            buttons: ['Я передумал', 'Сдать и выйти', {
                text: 'Выйти',
                color: 'warning',
                action: 'other',
                position: 'right',
            },],
        })
    )
    if (action.action === modalTemplates.confirm.actions.confirm) {
        history.push('/check-list', {close: true})
    } else if (action.action === 'other') {
        dispatch(resetShift())
        dispatch(logoutAdmin())
    }
}

const changeShiftModal = (lcode) => async (dispatch) => {
    const action = await callModal(
        modalTemplates.confirm({
            title:
                'Произошла смена администраторов на объекте, но пересменка не совершена. Пожалуйста, проведите пересменку.',
            text: 'Предыдущий администратора ушел со смены, не сдав ее через пересменку',
            buttons: ['Напомнить позже', 'Провести пересменку', {
                text: 'Перейти к смене',
                color: 'warning',
                action: 'other',
                position: 'right',
            }]
        })
    )
    if (action.action === modalTemplates.confirm.actions.confirm) {
        dispatch(logoutAdmin())
        history.push('/login?shiftChanging=true', {shift: true})
    } else if (action.action === 'other') {
        dispatch(openShiftModal(lcode))
    }
}

const openShiftModal = (lcode) => async (dispatch) => {
    const action = await callModal(
        modalTemplates.confirm({
            text:
                'Вы желаете принять смену?',
            buttons: ['Напомнить позже', 'Принять смену и заполнить чек-листы', {
                text: 'Принять смену',
                color: 'warning',
                action: 'other',
                position: 'right',
            }]
        })
    )
    if (action.action === modalTemplates.confirm.actions.confirm) {
        history.push('/check-list')
    } else if (action.action === 'other') {
        await dispatch(openShift(lcode))
    }
}

const openCheckListModal = async () => {
    const action = await callModal(
        modalTemplates.confirm({
            text:
                'Вы желаете заполнить чек-листы?',
            buttons: ['Напомнить позже', 'Заполнить чек-листы'],
        })
    )
    if (action.action === modalTemplates.confirm.actions.confirm) {
        history.push('/check-list')
    }
}
