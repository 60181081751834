import * as types from './types'
import {getCapacityWithCheckins} from '../../../../Modules/api/ccAPI/requests'

const loadingRequest = payload => ({
    type: types.LOADING_DATA,
    payload,
})

const loadingDataSuccess = payload => ({
    type: types.LOAD_DATA_SUCCESS,
    payload,
})

const loadingFailure = () => ({
    type: types.LOAD_DATA_FAILURE,
})


export const loadWithCheckins = ({date}) => async (dispatch, getState) => {
    const {session} = getState()
    const lcode = session.lcode
    dispatch(loadingRequest({date}))

    try {

        const data = await getCapacityWithCheckins({lcode, date})

        dispatch(loadingDataSuccess(data))
    } catch (err) {
        dispatch(loadingFailure())
    }
}

export const reset = () => ({
    type: types.RESET,
})

let intervalId = null

export const init = () => (dispatch) => {if (intervalId) return
    intervalId = setInterval(() => dispatch(loadWithCheckins({})), 2 * 1000 * 60)

}
